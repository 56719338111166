import React from 'react';
import './Login.css';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import ToastInfo from "../../components/Toast";
import {resetNewPassword, newError, clearErr} from "../../reducers/login";

class SetNewPassword extends React.Component {

  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      curPass: '',
      curPass2: '',
      passReset: false,
      token: urlParams.get('access_token')
    }
  }

  componentWillUnmount() {
    this.props.clearErr()
  }

  handleEntry(e, type) {
    if (type === 'password') {
      this.setState({curPass: e.target.value})
    } else if (type === 'password2') {
      this.setState({curPass2: e.target.value})
    }
  }

  handleClick(type) {
    if (this.props.isFetching) {
      return
    }
    switch (type) {
      case 'login':
        this.props.history.push('/login');
        return;
      case 'home':
        this.props.history.push('/');
        return;
      default:
        return
    }
  }

  handleSubmit() {
    if (this.state.curPass === this.state.curPass2) {
      this.props.resetNewPassword(this.state.curPass, this.state.token)
    } else {
      this.props.newError({error: {message: 'Please make sure your passwords match!'}})
    }
  }

  handleClose() {
    this.props.clearErr();
  }

  render() {
    console.log(this.state.token)
    if (this.props.newPass) {
      return (
        <div className='loginScreen'>
          <div className='loginLeft'/>
          <div className='loginRight'>
            <div id='loginTitle'>Password Set!</div>
            <div className='bottomSupport'>
              <div>You may now login with the new password!</div>
            </div>
            <div className='bottomLinkContainer'>
              <div id='loginButtons' onClick={() => this.handleClick('home')}>Home</div>
              <div id='loginButtons' onClick={() => this.handleClick('login')}>Login</div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='loginScreen'>
        <div className='loginLeft'/>
        <div className='loginRight'>
          <div id='loginTitle'>Reset Password</div>
          <input className='loginEntryBox'
                 type='password'
                 placeholder='New Password'
                 onChange={(e) => {
                   this.handleEntry(e, 'password')
                 }}
                 value={this.state.curPass}
                 onKeyPress={(e) => {
                   if (e.key === 'Enter') {
                     this.handleSubmit()
                   }
                 }}
          />
          <input className='loginEntryBox'
                 type='password'
                 placeholder='Confirm your password'
                 onChange={(e) => {
                   this.handleEntry(e, 'password2')
                 }}
                 value={this.state.curPass2}
                 onKeyPress={(e) => {
                   if (e.key === 'Enter') {
                     this.handleSubmit()
                   }
                 }}
          />
          <div className='linkContainer'>
            <div id='createButton' onClick={() => this.handleSubmit()}>Set!</div>
          </div>
          <div className='loader' style={{opacity: this.props.isFetching ? 1 : 0}}/>
          <div className='bottomLinkContainer'>
            <div id='loginButtons' onClick={() => this.handleClick('home')}>Home</div>
            <div id='loginButtons' onClick={() => this.handleClick('login')}>Login</div>
          </div>
        </div>
        { (this.props.err) ?
          <ToastInfo
            key={'err'}
            show={this.props.err}
            delay={6000}
            onClose={() => this.handleClose()}
            header='Failed!'
            body={
              <div style={{marginRight: '70px'}}>{this.props.err}</div>
            }
          /> : <div/>
        }
      </div>
    )
  }

}

const actions = {
  resetNewPassword, newError, clearErr
};

const mapStateToProps = (state) => ({
  newPass: state.sync.newPass,
  fetching: state.login.fetching,
  err: state.login.err
});


const SNP = connect(mapStateToProps, actions)(SetNewPassword);
export default withRouter(SNP)