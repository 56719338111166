import React from "react";
const BoxControl = ({id, onClick, onClickArg, handlePropagation, icon, text}) => (
  <div className='sidebutton'
       id={id}
       onClick={(e) => onClick(e, onClickArg)}
       onMouseDown={(e) => handlePropagation(e)}
       onTouchStart={(e) => handlePropagation(e)}
       onMouseUp={(e) => handlePropagation(e)}
       onTouchEnd={(e) => handlePropagation(e)}
       onMouseMove={(e) => handlePropagation(e)}
       onTouchMove={(e) => handlePropagation(e)}
  >{icon}<div className='tooltiptext'>{text}</div></div>
);
export default BoxControl