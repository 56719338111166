import React from 'react'
import Toast from 'react-bootstrap/Toast'
import './Main.css'

class ToastInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      timeElapsed: 0
    };
    this.inter = 0;
  }
  incrementPerc() {
    this.inter = setInterval(() => {
      if (this.state.timeElapsed >= this.props.delay - 600) {
        clearInterval(this.inter);
        this.props.onClose();
        return
      }
      this.setState({percentage: this.state.timeElapsed/this.props.delay, timeElapsed: this.state.timeElapsed + 10})
    }, 10);
  }

  componentDidMount() {
    this.incrementPerc()
  }

  componentWillUnmount() {
    clearInterval(this.inter)
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 5,
          right: 5,
        }}
      >
        <Toast onClose={() => this.props.onClose()} show={this.props.show}>
          <Toast.Header style={{backgroundColor: 'darkslategray'}}>
            <strong className="mr-auto" style={{color: 'blanchedalmond'}} >{this.props.header}</strong>
          </Toast.Header>
          <div style={{width: '400px', height: '2px', backgroundColor: 'blanchedalmond'}}>
            <div style={{height: '100%', backgroundColor: 'red', width: this.state.percentage*100 + '%'}} />
          </div>
          <Toast.Body style={{backgroundColor: 'blanchedalmond'}} >
            <div style={{
              width: '400px'
            }}>
              {this.props.body}
            </div>
          </Toast.Body>
        </Toast>
      </div>
    )
  }
}
export default ToastInfo
