import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {clearErr, updateActiveBoard} from "../reducers/login";
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {deleteBoard, nameChange} from "../reducers/sync";

class Board extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      curName: this.props.boardName,
      dragged: false,
      isEditName: false
    };
    this.curRef = React.createRef();
    this.textRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.activeBoard && (this.props.boardId === this.props.activeBoard)) {
      setTimeout(() => {
        this.curRef.current.scrollIntoView(true)
      }, 500)
    }
  }

  componentDidUpdate(prevprops, prevState, ss) {
    if ((prevprops.drag !== this.props.drag) && this.props.drag) {
      this.setState({dragged: this.props.drag})
    } else if ((prevprops.drag !== this.props.drag) && this.state.dragged && !this.props.drag) {
      setTimeout(() => this.setState({dragged: false}), 10)
    }
    if ((prevState.isEditName !== this.state.isEditName) && this.state.isEditName) {
      this.textRef.current.select()
    }
  }

  handleClick() {
    if ((this.props.boardId === this.props.activeBoard) || this.state.dragged) {
      this.setState({dragged: false});
      return
    }
    if (!this.props.inSync || this.props.fetching) {
      if (window.confirm('Unsaved changes, continue?')) {
        this.props.updateActiveBoard(this.props.boardId, this.props.token)
      } else {
        return
      }
    }
    this.props.updateActiveBoard(this.props.boardId, this.props.token)
  }

  handleDelete(e) {
    e.stopPropagation();
    if (window.confirm('Board will be deleted forever, are you sure?')) {
      this.props.deleteBoard(this.props.token, this.props.boardId)
    }
  }

  handleEdit(e) {
    e.stopPropagation();
    this.setState({isEditName: true})
  }

  editDone(e) {
    if (e) e.stopPropagation();
    if (this.state.curName !== this.props.boardName) {
      this.props.nameChange(this.props.token, this.props.boardId, this.props.userId, this.state.curName)
    }
    this.setState({isEditName: false})
  }

  handleCurName(e) {
    this.setState({curName: e.target.value})
  }

  render() {
    return <div ref={this.curRef} key={'br' + this.props.boardId} className='boardWrapper'
                id={(this.props.activeBoard === this.props.boardId) ?
                  'selectedBoard' :
                  ''
                } onClick={() => this.handleClick()}>
      <div className='boardName' id={(this.props.activeBoard === this.props.boardId) ?
        'selectedBoardName' :
        ''
      }>
        {this.state.isEditName ?
          <textarea className='boardName' id='boardTextArea'
                    ref={this.textRef}
                    onChange={(e) => this.handleCurName(e)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.editDone(e)
                      }
                    }}
                    value={this.state.curName}/> :
          <div className='boardName'>
            {this.props.boardName}
          </div>}
      </div>
      {(this.props.activeBoard === this.props.boardId) ?
        <div className='boardButtonWrap'>
          {this.state.isEditName ?
            <div className='boardButton' onClick={(e) => this.editDone(e)}><DoneIcon/></div> :
            <div className='boardButton' onClick={(e) => this.handleEdit(e)}><EditIcon/></div>}
          <div className='boardButton' onClick={(e) => this.handleDelete(e)}><DeleteOutlineIcon/></div>
        </div> : <div/>
      }
    </div>
  }

}

const actions = {
  clearErr, updateActiveBoard, deleteBoard, nameChange
};

const mapStateToProps = (state) => ({
  token: state.login.token,
  activeBoard: state.login.activeBoard,
  inSync: state.sync.inSync,
  fetching: state.login.fetching,
  userId: state.login.user.id
});


const B = connect(mapStateToProps, actions)(Board);
export default withRouter(B)