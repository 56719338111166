import React from 'react';
import {connect} from 'react-redux';
import './BoardList.css';
import {withRouter, Link} from "react-router-dom";
import {clearErr} from "../reducers/login";
import {unsynced, trySync, mounted} from "../reducers/sync";
import Board from './Board'

//import ToastInfo from './Toast';

class BoardList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      drag: false,
      //abChanged: true,
      firstLoad: true
    };
    this.interval = 0;
  }

  componentDidUpdate(prevProps, s, ss) {
    if (!this.props.token) {
      return
    }
    if (this.props.abChanged) {
      if (this.state.firstLoad) {
        this.props.mounted();
        this.setState({firstLoad: false})
      }
      //return this.setState({abChanged: true})
    }
    if (this.props.abChanged) {
      return
    }
    if ((prevProps.boxes !== this.props.boxes) || (prevProps.activeBox !== this.props.activeBox)) {
      this.props.unsynced()
    }
  }

  trySync() {
    if (!this.props.inSync && this.props.token) {
      this.props.trySync(this.props.token, this.props.boxes, this.props.activeBox, this.props.activeBoard, this.props.userId)
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload);
    this.interval = setInterval(() => {
      this.trySync()
    }, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
    clearInterval(this.interval)
  }

  beforeunload = (e) => {
    if (!this.props.inSync && this.props.token) {
      e.preventDefault();
      e.returnValue = '';
    } else {
      delete e['returnValue'];
    }
  };

  drag = false;
  diffx = 0;
  diffy = 0;
  startx = 0;
  starty = 0;

  onMouseUp(e) {
    this.setState({drag: false});
    if (!e) {
      e = window.event;
    }
    this.drag = false
    var start = 1, diffx = this.diffx, diffy = this.diffy, listRef = this.listRef,
      animate = function () {
        var step = Math.sin(start);
        if (step <= 0) {
          window.cancelAnimationFrame(animate);
        } else {
          listRef.scrollLeft += diffx * step;
          listRef.scrollTop += diffy * step;
          start -= 0.02;
          window.requestAnimationFrame(animate);
        }
      };
    animate();
  }

  handleClose() {
    this.props.clearErr();
  }

  onMouseDown(e) {
    if (!e) {
      e = window.event;
    }
    if (e.target && e.target.nodeName === 'IMG') {
      e.preventDefault();
    } else if (e.srcElement && e.srcElement.nodeName === 'IMG') {
      e.returnValue = false;
    }
    this.startx = e.clientX + this.listRef.scrollLeft;
    this.starty = e.clientY + this.listRef.scrollTop;
    this.diffx = 0;
    this.diffy = 0;
    this.drag = true;
  }

  onMouseMove(e) {
    if (this.drag === true) {
      this.setState({drag: true});
      if (!e) {
        e = window.event;
      }
      this.diffx = (this.startx - (e.clientX + this.listRef.scrollLeft));
      this.diffy = (this.starty - (e.clientY + this.listRef.scrollTop));
      this.listRef.scrollLeft += this.diffx;
      this.listRef.scrollTop += this.diffy;
    }
  }

  render() {
    if (!this.props.token) {
      if (window.innerWidth < 450) {
        return <div id='noBoards' style={{minWidth: '50px'}}>
          <Link to='/new-user'>Create</Link>
          /
          <Link to='/login'>login</Link>
        </div>
      }
      return <div id='noBoards'>
        Please
        <div className='invisText'>_</div>
        <Link to='/new-user'>create an account</Link>
        <div className='invisText'>_</div>
        or
        <div className='invisText'>_</div>
        <Link to='/login'>login</Link>
        <div className='invisText'>_</div>
        to save boards!
      </div>
    } else {
      return (
        <div id='boardList'
             ref={(ref) => this.listRef = ref}
             onMouseDown={(e) => this.onMouseDown(e)}
             onMouseMove={(e) => this.onMouseMove(e)}
             onMouseUp={(e) => this.onMouseUp(e)}>
          {(this.props.boards && (this.props.boards.length > 0)) ?
            this.props.boards.map((b) => {
              return <Board key={b.id + b.name} boardId={b.id} boardName={b.name} drag={this.state.drag}/>
            }) : <div id='noBoards'>Create a board to get started!</div>}
        </div>
      )
    }
  }
}

const actions = {
  clearErr, unsynced, trySync, mounted
};

const mapStateToProps = (state) => ({
  userId: state.login.user.id,
  token: state.login.token,
  inSync: state.sync.inSync,
  boards: state.login.boards,
  boxes: state.boxes,
  activeBox: state.meta.active,
  activeBoard: state.login.activeBoard,
  fetching: state.login.fetching,
  err: state.login.err,
  abChanged: state.sync.abChanged
});

const BL = connect(mapStateToProps, actions)(BoardList);
export default withRouter(BL)