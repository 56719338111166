import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './screens/App';
import Home from './screens/Home'
import Login from './screens/Login/Login'
import CreateAccount from './screens/Login/CreateAccount'
import ResetPassword from './screens/Login/ResetPassword'
import SetNewPassword from './screens/Login/SetNewPassword'
import {applyMiddleware, createStore} from 'redux'
import rootReducer from './reducers'
import thunk from "redux-thunk";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter as Router, Route} from 'react-router-dom'

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['sync']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    applyMiddleware(thunk)
  );
  const persistor = persistStore(store);
  return {store, persistor};
}

const {store, persistor} = configureStore();

ReactDOM.render(
  <div id='mainBody'>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route exact path="/" component={Home}/>
          <Route path="/app" component={App}/>
          <Route path="/login" component={Login}/>
          <Route path="/new-user" component={CreateAccount}/>
          <Route path="/reset-password" component={ResetPassword}/>
          <Route path="/set-password" component={SetNewPassword}/>
        </Router>
      </PersistGate>
    </Provider>
  </div>, document.getElementById('root'));
