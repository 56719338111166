import {HOST} from '../config'
import {homeRedirect, unsynced, synced, accountCreated, abChanged, newPass} from "./sync";
import {clearAll, loadBoxes} from "./box";
import {loadMeta} from "./meta";

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const REQUEST_LOGIN = 'REQUEST_LOGIN';
const REQUEST = 'REQUEST';
const REQUEST_FINISHED = 'REQUEST_FINISHED';
const LOGIN_ERROR = 'LOGIN_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';
const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
const UPDATE_ACTIVE_BOARD = 'UPDATE_ACTIVE_BOARD';
export const LOGOUT = 'LOGOUT';

export function newError(e) {
  if (!e.error) return {type: REQUEST_FINISHED};
  return {
    type: LOGIN_ERROR,
    message: e.error.message
  }
}

function loginSuccess(json) {
  return {
    type: LOGIN_SUCCESS,
    res: json
  }
}

function tokenSuccess(json) {
  return {
    type: TOKEN_SUCCESS,
    res: json
  }
}

function reqLogin() {
  return {
    type: REQUEST_LOGIN
  }
}

function logoutSucess() {
  return {
    type: LOGOUT
  }
}

export function updateAB(ab) {
  return {
    type: UPDATE_ACTIVE_BOARD,
    activeBoard: ab
  }
}

export function makeReq() {
  return {
    type: REQUEST
  }
}

export function reqFin() {
  return {
    type: REQUEST_FINISHED
  }
}

export function clearErr() {
  return {
    type: CLEAR_ERROR
  }
}

export function checkToken(token) {
  return (dispatch, getState) => {
    let accessToken = '&access_token=' + token;
    let query = JSON.stringify({include: {relation: 'boards', scope: {fields: ['id', 'name']}}});
    let url = HOST + '/accounts/' + getState().login.user.id + '?filter=' + query + accessToken;
    dispatch(reqLogin());
    dispatch(homeRedirect());
    return fetch(url, {method: 'GET', headers: {Pragma: "no-cache"}})
      .then(response => response.json().then(json => ({
          status: response.status,
          json
        })
      )).then(({status, json}) => {
        if (status !== 200) {
          dispatch(logoutSucess())
        } else {
          dispatch(tokenSuccess(json));
          if (json.activeBoard) {
            dispatch(loadBoard(token, json.activeBoard))
          }
        }
      }).catch(err => {
        dispatch(newError(err));
        dispatch(reqFin())
      })
  }
}

export function loginUser(creds) {
  let url = HOST + '/accounts/login?include=user';
  return dispatch => {
    dispatch(reqLogin());
    return fetch(url, {
      method: 'POST',
      headers: {"Content-type": "application/json"},
      body: JSON.stringify({email: creds.email, password: creds.password})
    }).then(response =>
      response.json().then(json => ({
          status: response.status,
          json
        })
      ))
      .then(({status, json}) => {
        if (status !== 200) {
          dispatch(newError(json));
          dispatch(reqFin())
        } else {
          dispatch(loginSuccess(json));
          if (json.user.activeBoard) {
            dispatch(loadBoard(json.id, json.user.activeBoard))
          }
        }
      }).catch(err => {
        dispatch(newError(err));
        dispatch(reqFin())
      })
  }
}

export function requestLogout(token) {
  return (dispatch) => {
    dispatch(reqLogin());
    dispatch(clearAll());
    dispatch(logoutSucess());
    dispatch(loadMeta({active: ''}));
    let t = '?access_token=' + token;
    let url = HOST + '/accounts/logout' + t;
    return fetch(url, {
      method: 'POST',
      headers: {"Content-type": "application/json"}
    }).then(response =>
      response.json().then(json => ({
          status: response.status,
          json
        })
      ))
      .then(({status, json}) => {
        if (status !== 204) {
          dispatch(newError(json));
          dispatch(logoutSucess());
          dispatch(reqFin())
        } else {
          dispatch(synced());
          dispatch(logoutSucess())
        }
      }).catch(err => {
        dispatch(newError(err));
        dispatch(reqFin())
      })
  }
}

export function loadBoard(token, id) {
  return (dispatch) => {
    let accessToken = '?access_token=' + token;
    let url = HOST + '/boards/' + id + accessToken;
    dispatch(makeReq());
    return fetch(url, {method: 'GET', headers: {Pragma: "no-cache"}})
      .then(response => response.json().then(json => ({
          status: response.status,
          json
        })
      )).then(({status, json}) => {
        if (status !== 200) {

          dispatch(reqFin());
          dispatch(newError(json));

        } else {
          dispatch(reqFin());
          dispatch(abChanged(true));
          dispatch(updateAB(id));
          dispatch(loadBoxes(json.data));
          dispatch(loadMeta(json.meta));
          dispatch(abChanged(false))
        }
      }).catch(err => {
        dispatch(
          dispatch(newError(err)));
        dispatch(reqFin())
      })
  }
}

export function updateActiveBoard(activeBoard, token) {
  return (dispatch, getState) => {
    let accessToken = '?access_token=' + token;
    let url = HOST + '/accounts/' + getState().login.user.id + accessToken;
    dispatch(makeReq());
    return fetch(url, {
      method: 'PATCH',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify({activeBoard: activeBoard})
    })
      .then(response => response.json().then(json => ({
          status: response.status,
          json
        })
      )).then(({status, json}) => {
        if (status !== 200) {
          dispatch(reqFin());
          dispatch(unsynced());
          dispatch(newError(json));
        } else {
          dispatch(synced());
          dispatch(loadBoard(token, activeBoard));
        }
      }).catch(err => {
        dispatch(newError(err));
        dispatch(reqFin())
      })
  }
}

export function updateABAfterDelete(activeBoard, token) {
  return (dispatch, getState) => {
    let accessToken = '?access_token=' + token;
    let url = HOST + '/accounts/' + getState().login.user.id + accessToken;
    dispatch(makeReq());
    return fetch(url, {
      method: 'PATCH',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify({activeBoard: activeBoard})
    })
      .then(response => response.json().then(json => ({
          status: response.status,
          json
        })
      )).then(({status, json}) => {
        if (status !== 200) {
          dispatch(reqFin());
          dispatch(unsynced());
          dispatch(newError(json));
        } else {
          dispatch(synced());
          dispatch(checkToken(token))
        }
      }).catch(err => {
        dispatch(newError(err));
        dispatch(reqFin())
      })
  }
}

export function newUser(username, email, password) {
  return (dispatch) => {
    dispatch(reqLogin());
    let url = HOST + '/accounts';
    return fetch(url, {
      method: 'POST',
      headers: {"Content-type": "application/json"},
      body: JSON.stringify({email: email, username: username, password: password})
    }).then(response =>
      response.json().then(json => ({
          status: response.status,
          json
        })
      ))
      .then(({status, json}) => {
        if (status !== 200) {
          dispatch(newError(json));
          dispatch(reqFin())
        } else {
          dispatch(clearAll());
          dispatch(loadMeta({active: ''}));
          dispatch(reqFin());
          dispatch(accountCreated())
        }
      }).catch(err => {
        dispatch(newError(err));
        dispatch(reqFin())
      })
  }
}

export function resetPassword(email) {
  return (dispatch) => {
    dispatch(clearAll());
    dispatch(logoutSucess());
    dispatch(loadMeta({active: ''}));
    let url = HOST + '/accounts/reset';
    return fetch(url, {
      method: 'POST',
      headers: {"Content-type": "application/json"},
      body: JSON.stringify({email: email})
    }).then(response =>
      response.json().then(json => ({
          status: response.status,
          json
        })
      )).then(({status, json}) => {
      if (status !== 204) {
        dispatch(reqFin())
      } else {
        dispatch(reqFin());
      }
    }).catch(err => {
      dispatch(newError(err));
      dispatch(reqFin())
    })
  }
}

export function resetNewPassword(password, token) {
  return (dispatch) => {
    dispatch(clearAll());
    dispatch(logoutSucess());
    dispatch(makeReq());
    dispatch(loadMeta({active: ''}));
    let url = HOST + '/accounts/reset-password?access_token=' + token;
    return fetch(url, {
      method: 'POST',
      headers: {"Content-type": "application/x-www-form-urlencoded"},
      body: 'newPassword=' + password
    }).then(response => {
      if (response.status !== 204) {
        response.json().then(json => ({
            status: response.status,
            json
          })
        ).then(({status, json}) => {
          dispatch(newError(json));
          dispatch(reqFin())
        })
      } else {
        dispatch(newPass());
        dispatch(reqFin());
      }
    }).catch(err => {
      dispatch(newError(err));
      dispatch(reqFin())
    })
  }
}

const login = (state = {
  fetching: false,
  user: {},
  token: '',
  activeBoard: '',
  isAuthenticated: false,
  boards: [],
  err: ''
}, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        fetching: false,
        user: action.res.user,
        token: action.res.id,
        activeBoard: action.res.user.activeBoard,
        isAuthenticated: true,
      };
    case 'TOKEN_SUCCESS':
      return {
        ...state,
        fetching: false,
        user: action.res,
        activeBoard: action.res.activeBoard,
        isAuthenticated: true,
        boards: action.res.boards
      };
    case 'LOGOUT':
      return {
        fetching: false,
        user: {},
        token: '',
        activeBoard: {},
        boards: [],
        isAuthenticated: false
      };
    case 'REQUEST':
      return {...state, fetching: true};
    case 'REQUEST_FINISHED':
      return {...state, fetching: false};
    case 'REQUEST_LOGIN':
      return {...state, fetching: true, isAuthenticated: false};
    case 'UPDATE_ACTIVE_BOARD':
      return {...state, activeBoard: action.activeBoard};
    case 'LOGIN_ERROR':
      return {...state, err: action.message};
    case 'CLEAR_ERROR':
      return {...state, err: ''};
    default:
      return state
  }
};

export default login