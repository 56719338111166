import {checkToken, makeReq, reqFin, updateABAfterDelete, updateActiveBoard} from "./login";
import {clearAll} from "./box";
import {loadMeta} from "./meta";
import {newError} from "./login";
import {HOST} from "../config";

const SYNCED = 'SYNCED';
const UNSYNCED = 'UNSYNCED';
const HOME_REDIRECT = 'HOME_REDIRECT';
const ALL_MOUNTED = 'ALL_MOUNTED';
const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const AB_CHANGED = 'AB_CHANGED';
const NEW_PASS = 'NEW_PASS';


export function homeRedirect() {
  return {type: HOME_REDIRECT}
}

export function unsynced() {
  return {type: UNSYNCED}
}

export function synced() {
  return {type: SYNCED}
}

export function mounted() {
  return {type: ALL_MOUNTED}
}

export function accountCreated() {
  return {type: ACCOUNT_CREATED}
}

export function abChanged(val) {
  return {
    type: AB_CHANGED,
    val: val
  }
}

export function newPass() {
  return {
    type: NEW_PASS
  }
}

export function newBoard(token, boxes, activeBox, userId) {
  return (dispatch) => {
    let accessToken = '?access_token=' + token;
    let url = HOST + '/boards' + accessToken;
    dispatch(makeReq());
    return fetch(url, {
      method: 'POST',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify({data: boxes, meta: {activeBox: activeBox}, accountId: userId})
    }).then(response => response.json().then(json => ({
        status: response.status,
        json
      })
    )).then(({status, json}) => {
      if (status !== 200) {
        dispatch(reqFin());
        dispatch(unsynced());
        dispatch(newError(json));
      } else {
        dispatch(updateActiveBoard(json.id, token));
        dispatch(checkToken(token))
      }
    }).catch(err => {
      dispatch(newError(err));
      dispatch(reqFin())
    })
  }
}

export function trySync(token, boxes, activeBox, activeBoard, userId) {
  return (dispatch) => {
    let accessToken = '?access_token=' + token;
    let url = HOST + '/boards' + accessToken;
    dispatch(makeReq());
    return fetch(url, {
      method: 'PATCH',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify({id: activeBoard, data: boxes, meta: {active: activeBox}, accountId: userId})
    }).then(response => response.json().then(json => ({
        status: response.status,
        json
      })
    )).then(({status, json}) => {
      if (status !== 200) {
        dispatch(reqFin());
        dispatch(unsynced());
        dispatch(newError(json));
      } else {
        dispatch(synced());
        dispatch(reqFin());
      }
    }).catch(err => {
      dispatch(newError(err));
      dispatch(reqFin())
    })
  }
}

export function nameChange(token, boardId, userId, newName) {
  return (dispatch) => {
    let accessToken = '?access_token=' + token;
    let url = HOST + '/boards' + accessToken;
    dispatch(makeReq());
    return fetch(url, {
      method: 'PATCH',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify({id: boardId, name: newName, accountId: userId})
    }).then(response => response.json().then(json => ({
        status: response.status,
        json
      })
    )).then(({status, json}) => {
      if (status !== 200) {
        dispatch(reqFin());
        dispatch(unsynced());
        dispatch(newError(json));
      } else {
        dispatch(synced());
        dispatch(reqFin());
        dispatch(checkToken(token))
        //dispatch(updateAB({id: json.id, name: json.name}));
      }
    }).catch(err => {
      dispatch(newError(err));
      dispatch(reqFin())
    })
  }
}

export function deleteBoard(token, boardId) {
  return (dispatch) => {
    let accessToken = '?access_token=' + token;
    let url = HOST + '/boards/' + boardId + accessToken;
    dispatch(makeReq());
    return fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then(response => response.json().then(json => ({
        status: response.status,
        json
      })
    )).then(({status, json}) => {
      if (status !== 200) {
        dispatch(reqFin());
        dispatch(unsynced());
        dispatch(newError(json));
      } else {
        dispatch(abChanged(true));
        dispatch(reqFin());
        dispatch(clearAll());
        dispatch(loadMeta({active: ''}));
        dispatch(updateABAfterDelete('', token));
        dispatch(synced());
        dispatch(abChanged(false))
        //dispatch(updateAB({id: json.id, name: json.name}));
      }
    }).catch(err => {
      dispatch(newError(err));
      dispatch(reqFin())
    })
  }
}

const sync = (state = {
  inSync: true,
  homeRedirect: true,
  waitForMounts: true,
  accountCreated: false,
  abChanged: false,
  newPass: false
}, action) => {
  switch (action.type) {
    case SYNCED:
      return {...state, inSync: true};
    case UNSYNCED:
      return {...state, inSync: false};
    case HOME_REDIRECT:
      return {...state, homeRedirect: false};
    case ALL_MOUNTED:
      return {...state, waitForMounts: false};
    case ACCOUNT_CREATED:
      return {...state, accountCreated: true};
    case AB_CHANGED:
      return {
        ...state,
        abChanged: action.val
      };
    case NEW_PASS:
      return {
        ...state, newPass: true
      };
    default:
      return state;
  }
};

export default sync