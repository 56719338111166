import React from 'react';
import './Login.css';
import {connect} from 'react-redux'
import {withRouter, Redirect} from "react-router-dom";
import {loginUser, clearErr} from "../../reducers/login";
import ToastInfo from '../../components/Toast'

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      curEmail: '',
      curPass: ''
    }
  }

  componentWillUnmount() {
    this.props.clearErr()
  }

  handleEntry(e, type) {
    if (type === 'email') {
      this.setState({curEmail: e.target.value})
    } else {
      this.setState({curPass: e.target.value})
    }
  }

  handleClick(type) {
    if (this.props.isFetching) {
      return
    }
    switch (type) {
      case 'password':
        this.props.history.push('/reset-password');
        return;
      case 'new':
        this.props.history.push('/new-user');
        return;
      case 'home':
        this.props.history.push('/');
        return;
      default:
        return
    }
  }

  handleSubmit() {
    this.props.loginUser({email: this.state.curEmail, password: this.state.curPass})
  }

  handleClose() {
    this.props.clearErr();
  }

  render() {
    if (this.props.isAuthenticated) {
      return (
        <Redirect to={{pathname: '/app'}}/>
      )
    }
    return (
      <div className='loginScreen'>
        <div className='loginLeft'/>
        <div className='loginRight'>
          <div id='loginTitle'>Login</div>
          <input className='loginEntryBox'
                 type='email'
                 placeholder='example@workbor.de'
                 onChange={(e) => {this.handleEntry(e, 'email')}}
                 value={this.state.curEmail}
                 onKeyPress={(e) => {if (e.key === 'Enter') { this.handleSubmit() }}}
          />
          <input className='loginEntryBox'
                 type='password'
                 placeholder='xxxxx'
                 onChange={(e) => {this.handleEntry(e, 'pass')}}
                 value={this.state.curPass}
                 onKeyPress={(e) => {if (e.key === 'Enter') { this.handleSubmit() }}}
          />
          <div className='linkContainer'>
            <div id='loginButtons' onClick={() => this.handleClick('password')}>Forgot Password?</div>
            <div id='loginButtons' onClick={() => this.handleSubmit()} >Login</div>
          </div>
          <div className='loader' style={{opacity: this.props.isFetching ? 1 : 0}} />
          <div className='bottomLinkContainer'>
            <div id='loginButtons' onClick={() => this.handleClick('new')}>New User</div>
            <div id='loginButtons' onClick={() => this.handleClick('home')}>Home</div>
          </div>
        </div>
        {((this.props.err === 'username or email is required') ||
          (this.props.err === 'login failed') ||
          (this.props.err === 'login failed as the email has not been verified')) ?
          <ToastInfo
            key={'err'}
            show={this.props.err}
            delay={6000}
            onClose={() => this.handleClose()}
            header='Failed!'
            body={
              <div style={{marginRight: '70px'}}>{(this.props.err === 'login failed as the email has not been verified' ?
              'Please verify your email before logging in!' : this.props.err
              )}</div>
            }
          /> :
          <div/>
        }
      </div>
    )
  }
}

const actions = {
  loginUser, clearErr
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  isFetching: state.login.fetching,
  err: state.login.err
});


const L = connect(mapStateToProps, actions)(Login);
export default withRouter(L)