const SET_ACTIVE = 'SET_ACTIVE';
const SET_TO_DELETE = 'SET_TO_DELETE';
const LOAD_META = 'LOAD_META';

export function setActiveBox(box) {
  return {
    type: SET_ACTIVE,
    box: box
  }
}

export function setToDelete(id) {
  return {
    type: SET_TO_DELETE,
    id: id
  }
}

export function loadMeta(meta) {
  return {
    type: LOAD_META,
    meta: meta
  }
}

const meta = (state = {
  active: '',
  toDelete: 0
}, action) => {
  switch (action.type) {
    case SET_ACTIVE:
      return {
        ...state,
        active: action.box
      };
    case SET_TO_DELETE:
      return {
        ...state,
        toDelete: action.id
      };
    case LOAD_META:
      return {
        ...state,
        ...action.meta
      };
    default:
      return state
  }
};

export default meta
