import React from 'react';
import './Login.css';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import {clearErr, resetPassword} from "../../reducers/login";

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      curEmail: '',
      passReset: false
    }
  }

  componentWillUnmount() {
    this.props.clearErr()
  }

  handleEntry(e, type) {
    if (type === 'email') {
      this.setState({curEmail: e.target.value})
    }
  }

  handleClick(type) {
    if (this.props.isFetching) {
      return
    }
    switch (type) {
      case 'login':
        this.props.history.push('/login');
        return;
      case 'new':
        this.props.history.push('/new-user');
        return;
      case 'home':
        this.props.history.push('/');
        return;
      default:
        return
    }
  }

  handleSubmit() {
    this.setState({passReset: true});
    this.props.resetPassword(this.state.curEmail)
  }

  render() {
    if (this.state.passReset) {
      return (
        <div className='loginScreen'>
          <div className='loginLeft'/>
          <div className='loginRight'>
            <div id='loginTitle'>Reset Password</div>
            <div className='bottomSupport'>
              <div>If an account with that email exists, an email will be sent with instructions to reset your password!</div>
              <div>(Please check your spam folders as well)</div>
            </div>
            <div className='bottomLinkContainer'>
              <div id='loginButtons' onClick={() => this.handleClick('new')}>New User</div>
              <div id='loginButtons' onClick={() => this.handleClick('login')}>Login</div>
              <div id='loginButtons' onClick={() => this.handleClick('home')}>Home</div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='loginScreen'>
        <div className='loginLeft'/>
        <div className='loginRight'>
          <div id='loginTitle'>Reset Password</div>
          <input className='loginEntryBox'
                 type='email'
                 placeholder='Please enter your email'
                 onChange={(e) => {this.handleEntry(e, 'email')}}
                 value={this.state.curEmail}
                 onKeyPress={(e) => {if (e.key === 'Enter') { this.handleSubmit() }}}
          />
          <div className='linkContainer'>
            <div id='loginButtons' onClick={() => this.handleSubmit()} >Reset my password!</div>
          </div>
          <div className='bottomLinkContainer'>
            <div id='loginButtons' onClick={() => this.handleClick('new')}>New User</div>
            <div id='loginButtons' onClick={() => this.handleClick('login')}>Login</div>
            <div id='loginButtons' onClick={() => this.handleClick('home')}>Home</div>
          </div>
        </div>
      </div>
    )
  }
}

const actions = {
  resetPassword, clearErr
};

const mapStateToProps = (state) => ({
});


const RP = connect(mapStateToProps, actions)(ResetPassword);
export default withRouter(RP)