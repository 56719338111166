const ADD_BOX = 'ADD_BOX';
const CHANGE_BOX = 'CHANGE_BOX';
const CLEAR_ALL = 'CLEAR_ALL';
const DELETE_BOX = 'DELETE_BOX';
const ADD_BOXES = 'ADD_BOXES';
const LOAD_BOXES = 'LOAD_BOXES';

export function addBox(box) {
  return {
    type: ADD_BOX,
    box: box
  }
}

export function changeBox(box) {
  return {
    type: CHANGE_BOX,
    box: box
  }
}

export function clearAll() {
  return {
    type: CLEAR_ALL
  }
}

export function deleteBox(id) {
  return {
    type: DELETE_BOX,
    id: id
  }
}

export function addBoxes(boxes) {
  return {
    type: ADD_BOXES,
    boxes: boxes
  }
}

export function loadBoxes(boxes) {
  return {type: LOAD_BOXES, boxes: boxes}
}

const boxes = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_BOXES':
      return action.boxes;
    case 'ADD_BOX':
      return [
        ...state,
        action.box
      ];
    case 'CHANGE_BOX':
      var indexC = state.findIndex((e) => {return e.id === action.box.id});
      var newStateC = [...state];
      newStateC[indexC] = { ...newStateC[indexC], ...action.box };
      return newStateC;
    case 'CLEAR_ALL':
      return [];
    case 'DELETE_BOX':
      var indexD = state.findIndex((e) => {return e.id === action.id});
      if (indexD === -1) {return state}
      var newStateD = [...state];
      newStateD.splice(indexD, 1);
      return newStateD;
    case 'ADD_BOXES':
      return [
        ...state,
        ...action.boxes
      ];
    default:
      return state
  }
};

export default boxes
