import React, {Component} from 'react';
import './Main.css';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {addBox, changeBox, deleteBox} from "../reducers/box";
import {setActiveBox, setToDelete} from "../reducers/meta";
import Box from './Box';
import ToastInfo from './Toast';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import {requestLogout} from "../reducers/login";


class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {clickX: 0, clickY: 0, init: false, snackBarOn: false, currentOutline: {}};
  }

  handleMouseDown(e, touch) {
    if (!touch) e.stopPropagation();
    this.mainDiv.focus();
    this.setState({clickX: e.clientX, clickY: e.clientY, init: true})
  }

  handleMouseUp(e, touch) {
    //if (!touch) e.stopPropagation();
    if (this.state.init) {
      this.setState({init: false});
      let width = (e.clientX - this.state.clickX);
      let height = (e.clientY - this.state.clickY);
      let pos;
      if (height > 0) {
        if (width < 0) {
          pos = {x: e.clientX, y: this.state.clickY}
        } else {
          pos = {x: this.state.clickX, y: this.state.clickY}
        }
      } else {
        if (width < 0) {
          pos = {x: e.clientX, y: e.clientY}
        } else {
          pos = {x: this.state.clickX, y: e.clientY}
        }
      }
      if (!((height * width === 0) || (Math.abs(height) < 40) || (Math.abs(width) < 40))) {
        let box = {
          id: Date.now(),
          x: pos.x,
          y: pos.y - 40,
          text: '',
          height: Math.abs(height),
          width: Math.abs(width),
          selected: true,
          fontSize: 16
        };
        this.props.addBox(box);
        this.props.setActiveBox(box.id)
      }
    }
  }

  handleTouchEvent(e, type) {
    //e.stopPropagation()
    if (e.touches.length > 1) {
      this.setState({init: false});
      return
    }
    if (type === 'start') {
      this.handleMouseDown(e.touches[0], true)
    } else {
      this.handleMouseUp(e.changedTouches[0], true)
    }
  }

  handleCloseDeleteToast(choice) {
    if (choice === 'undo') {
      this.props.setToDelete(0);
    } else {
      this.props.deleteBox(this.props.toDelete);
      this.props.setToDelete(0)
    }
  }

  handleDragBox(e) {
    if (this.state.init) {
      let width = (e.clientX - this.state.clickX);
      let height = (e.clientY - this.state.clickY);
      let pos;
      if (height > 0) {
        if (width < 0) {
          pos = {x: e.clientX, y: this.state.clickY}
        } else {
          pos = {x: this.state.clickX, y: this.state.clickY}
        }
      } else {
        if (width < 0) {
          pos = {x: e.clientX, y: e.clientY}
        } else {
          pos = {x: this.state.clickX, y: e.clientY}
        }
      }
      if (!((height * width === 0) || (Math.abs(height) < 1) || (Math.abs(width) < 1))) {
        let box = {
          x: pos.x,
          y: pos.y - 40,
          height: Math.abs(height),
          width: Math.abs(width),
        }
        this.setState({currentOutline: box})
      }
    } else {
      this.setState({currentOutline: {x: 0, y: 0, height: 0, width: 0}})
    }
  }

  handleTouchDragBox(e) {
  }

  handleLogout() {
    if (this.props.token) {
      this.props.requestLogout(this.props.token)
    }
    this.props.history.push('/login')
  }

  handlePropagation(e) {
    e.stopPropagation();
  }

  render() {
    if (this.props.token && !this.props.activeBoard) {
      return (
        <div className="Main">
          <div id='sideBar'>
            <div className='sidebutton'
                 id='logoutButton'
                 onClick={() => this.handleLogout()}
                 onMouseDown={(e) => this.handlePropagation(e)}
                 onTouchStart={(e) => this.handlePropagation(e)}
                 onMouseUp={(e) => this.handlePropagation(e)}
                 onTouchEnd={(e) => this.handlePropagation(e)}
                 onMouseMove={(e) => this.handlePropagation(e)}
                 onTouchMove={(e) => this.handlePropagation(e)}
            ><ExitToAppOutlinedIcon id={this.props.token ? 'rotIcon' : ''}/>
              <div className='tooltiptext'>{this.props.token ? 'Logout' : 'Login'}</div>
            </div>
          </div>
          <div id='emptyBoard'>Create or select a board at the top bar to get started!</div>
        </div>
      )
    }
    if (((this.props.token && this.props.activeBoard && this.props.boxes) || (!this.props.token)) && (this.props.boxes.length < 1)) {
      return (
        <div className="Main"
             ref={(ref) => this.mainDiv = ref}
             onMouseDown={(e) => this.handleMouseDown(e)}
             onTouchStart={(e) => this.handleTouchEvent(e, 'start')}
             onMouseUp={(e) => this.handleMouseUp(e)}
             onTouchEnd={(e) => this.handleTouchEvent(e, 'end')}
             onMouseMove={(e) => this.handleDragBox(e)}
             onTouchMove={(e) => this.handleTouchDragBox(e)}
        >
          <div id='sideBar'>
            <div className='sidebutton'
                 id='logoutButton'
                 onClick={() => this.handleLogout()}
                 onMouseDown={(e) => this.handlePropagation(e)}
                 onTouchStart={(e) => this.handlePropagation(e)}
                 onMouseUp={(e) => this.handlePropagation(e)}
                 onTouchEnd={(e) => this.handlePropagation(e)}
                 onMouseMove={(e) => this.handlePropagation(e)}
                 onTouchMove={(e) => this.handlePropagation(e)}
            ><ExitToAppOutlinedIcon id={this.props.token ? 'rotIcon' : ''}/>
              <div className='tooltiptext'>{this.props.token ? 'Logout' : 'Login'}</div>
            </div>
          </div>
          <div id='emptyBoard'>Click and drag to make a box!</div>
          {this.state.init ? <div className='boxOutline'
                                  style={{
                                    left: this.state.currentOutline.x,
                                    top: this.state.currentOutline.y,
                                    height: this.state.currentOutline.height - 10,
                                    width: this.state.currentOutline.width - 10,
                                    margin: 5
                                  }}/> : <div/>}
        </div>
      )
    }
    return (
      <div className="Main"
           ref={(ref) => this.mainDiv = ref}
           onMouseDown={(e) => this.handleMouseDown(e)}
           onTouchStart={(e) => this.handleTouchEvent(e, 'start')}
           onMouseUp={(e) => this.handleMouseUp(e)}
           onTouchEnd={(e) => this.handleTouchEvent(e, 'end')}
           onMouseMove={(e) => this.handleDragBox(e)}
           onTouchMove={(e) => this.handleTouchDragBox(e)}
      >
        <div id='sideBar'>
          <div className='sidebutton'
               id='logoutButton'
               onClick={() => this.handleLogout()}
               onMouseDown={(e) => this.handlePropagation(e)}
               onTouchStart={(e) => this.handlePropagation(e)}
               onMouseUp={(e) => this.handlePropagation(e)}
               onTouchEnd={(e) => this.handlePropagation(e)}
               onMouseMove={(e) => this.handlePropagation(e)}
               onTouchMove={(e) => this.handlePropagation(e)}
          ><ExitToAppOutlinedIcon id={this.props.token ? 'rotIcon' : ''}/>
            <div className='tooltiptext'>{this.props.token ? 'Logout' : 'Login'}</div>
          </div>
        </div>
        {(!this.props.waitForMounts || !this.props.token) ? this.props.boxes.map((e) => {
          if (e.id !== this.props.toDelete) {
            return <Box box={e} key={e.id + this.props.activeBoard}/>
          } else return <div key={this.props.toDelete + 'del'}/>
        }) : <div/>}
        {this.state.init ? <div className='boxOutline'
                                style={{
                                  left: this.state.currentOutline.x,
                                  top: this.state.currentOutline.y,
                                  height: this.state.currentOutline.height - 10,
                                  width: this.state.currentOutline.width - 10,
                                  margin: 5
                                }}/> : <div/>}
        {this.props.toDelete !== 0 ?
          <ToastInfo
            key={'deleted' + this.props.toDelete}
            show={this.props.toDelete !== 0}
            delay={6000}
            onClose={() => this.handleCloseDeleteToast('delete')}
            header='Deleted!'
            body={
              <div id='delToastButtons'>
                <div key={'undoDel' + this.props.toDelete} className='undoButton' onClick={() => this.handleCloseDeleteToast('undo')}>
                  UNDO
                </div>
                <div key={'confirmDel' + this.props.toDelete} className='undoButton' id='delConf'
                     onClick={() => this.handleCloseDeleteToast('delete')}>
                  CONFIRM
                </div>
              </div>
            }
          /> : <div/>}
      </div>
    );
  }
}

const actions = {
  addBox, changeBox, setActiveBox, deleteBox, setToDelete, requestLogout
};

const mapStateToProps = (state) => ({
  boxes: state.boxes,
  originalDimensions: state.meta.originalDimensions,
  currentDimensions: state.meta.currentDimensions,
  active: state.meta.active,
  toDelete: state.meta.toDelete,
  token: state.login.token,
  waitForMounts: state.sync.waitForMounts,
  activeBoard: state.login.activeBoard,
});


const M = connect(mapStateToProps, actions)(Main);
export default withRouter(M)