import React, { Component } from 'react';
import './App.css';
import '../components/app-buttons.css';
import Main from '../components/Main';
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router-dom";
import { clearAll, addBoxes } from "../reducers/box";
import {setActiveBox, setToDelete} from "../reducers/meta";
import ToastInfo from '../components/Toast';
import {checkToken, clearErr} from "../reducers/login";
import BoardList from '../components/BoardList';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import SyncIcon from '@material-ui/icons/Sync';
import {newBoard, trySync} from "../reducers/sync";

class App extends Component {

  state = {snackBarOn: false, tempBackup: [], toLogin: false};

  componentDidMount() {
    if (this.props.token) {
      this.props.checkToken(this.props.token)
    }
  }

  componentWillUnmount() {
    this.props.clearErr();
  }

  componentDidUpdate(prevProps, ps, ss) {
    if ((prevProps.token !== this.props.token) && !this.props.token && prevProps.isAuthenticated && !this.props.isAuthenticated) {
      this.setState({toLogin: true})
    }
  }

  handleClear() {
    if (this.props.boxes.length < 1) {return}
    this.setState({snackBarOn: true, tempBackup: this.props.boxes});
    this.props.clearAll();
    this.props.setActiveBox({});
    this.props.setToDelete(0)
  }

  handleUndo(e) {
    e.stopPropagation();
    this.props.addBoxes(this.state.tempBackup);
    this.setState({snackBarOn: false, tempBackup: []})
  }

  handleClose() {
    this.setState({snackBarOn: false, tempBackup: []})
  }

  createBoard() { // create a new blank board
    this.props.newBoard(this.props.token, [], '', this.props.userId)
  }

  trySync() {
    if (!this.props.inSync && this.props.token) {
      this.props.trySync(this.props.token, this.props.boxes, this.props.activeBox, this.props.activeBoard, this.props.userId)
    }
  }

  handleErrMsg() {
    this.props.clearErr()
  }

  render() {
    if (this.state.toLogin) {
      return <Redirect to={{pathname: '/login'}}/>
    }
    return (
      <div className="App">
        <div id='mainWrapper'>
          <Main/>
        </div>
        <div id="appHeader">
          <div className="headerContainer" id={this.props.token ? 'headerLoggedIn' : ''}>
            { (this.props.fetching) ? <div id='loaderWrap'><div className='loader' id='headerLoader' /></div> :
              <div id='homeButtonWrap' onClick={() => {this.props.history.push('/')}}><div id='homeButton'><strong>ωβ</strong><sub id='betaSub'>b</sub></div></div>
            }
            <div className='headerDivider'/>
            <BoardList/>
            <div className='headerDivider'/>
            <div className='cbutton' id='newBoardButton' onClick={(e) => this.trySync()}><SyncIcon/></div>
            <div className='cbutton' id='newBoardButton' onClick={(e) => this.createBoard()}><AddBoxOutlinedIcon/></div>
            <div className='cbutton' id='clearButton' onClick={(e) => this.handleClear()}><CancelPresentationOutlinedIcon/></div>
          </div>
        </div>
        {this.state.snackBarOn ?
          <ToastInfo
            key={'clear' + this.state.tempBackup[0].id}
            show={this.state.snackBarOn}
            delay={6000}
            onClose={() => this.handleClose()}
            header='Cleared!'
            body={
              <div key="undo" className='undoButton' onClick={(e) => this.handleUndo(e)}>
                UNDO
              </div>
            }
          /> :
          <div/>
        }
        {(this.props.error) ?
          <ToastInfo
            key={'err' + this.props.error}
            show={this.props.error}
            delay={6000}
            onClose={() => this.handleErrMsg()}
            header='Error!'
            body={
              this.props.error
            }
          /> :
          <div/>
        }
      </div>
    );
  }
}

const actions = {
  clearAll, addBoxes, setActiveBox, setToDelete, checkToken, newBoard, trySync, clearErr
};

const mapStateToProps = (state) => ({
  boxes: state.boxes,
  originalDimensions: state.meta.originalDimensions,
  currentDimensions: state.meta.currentDimensions,
  meta: state.meta,
  token: state.login.token,
  isAuthenticated: state.login.isAuthenticated,
  userId: state.login.user.id,
  fetching: state.login.fetching,
  inSync: state.sync.inSync,
  boards: state.login.boards,
  activeBox: state.meta.active,
  activeBoard: state.login.activeBoard,
  error: state.login.err
});

const A = connect(mapStateToProps, actions)(App);
export default withRouter(A)