import React from 'react';
import './Login.css';
import {connect} from 'react-redux'
import {withRouter, Redirect} from "react-router-dom";
import ToastInfo from "../../components/Toast";
import {clearErr, newError, newUser} from "../../reducers/login";

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curEmail: '',
      curPass: '',
      curPass2: '',
      curUsername: ''
    }
  }

  componentWillUnmount() {
    this.props.clearErr()
  }

  handleEntry(e, type) {
    if (type === 'email') {
      this.setState({curEmail: e.target.value})
    } else if (type === 'password') {
      this.setState({curPass: e.target.value})
    } else if (type === 'password2') {
      this.setState({curPass2: e.target.value})
    } else {
      this.setState({curUsername: e.target.value})
    }
  }

  handleClick(type) {
    if (this.props.isFetching) {
      return
    }
    switch (type) {
      case 'login':
        this.props.history.push('/login');
        return;
      case 'create':
        this.handleSubmit();
        return;
      case 'home':
        this.props.history.push('/');
        return;
      default:
        return
    }
  }

  handleSubmit() {
    if (this.state.curPass === this.state.curPass2) {
      if (this.state.curEmail.includes('@')) {
        this.props.newUser(this.state.curUsername, this.state.curEmail, this.state.curPass)
      } else {
        this.props.newError({error: {message: 'Please check the format of your email!'}})
      }
    } else {
      this.props.newError({error: {message: 'Please make sure your passwords match!'}})
    }
  }

  handleClose() {
    this.props.clearErr();
  }

  render() {
    if (this.props.isAuthenticated) {
      return (
        <Redirect to={{pathname: '/app'}}/>
      )
    }
    if (this.props.accountCreated) {
      return (
        <div className='loginScreen'>
          <div className='loginLeft'/>
          <div className='loginRight'>
            <div id='loginTitle'>Account created!</div>
            <div className='bottomSupport'>
              <div>An email has been sent with a confirmation link!</div>
              <div>(Please check your spam folders as well)</div>
            </div>
            <div className='bottomLinkContainer'>
              <div id='loginButtons' onClick={() => this.handleClick('home')}>Home</div>
              <div id='loginButtons' onClick={() => this.handleClick('login')}>Login</div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='loginScreen'>
        <div className='loginLeft'/>
        <div className='loginRight'>
          <div id='loginTitle'>New Account</div>
          <input className='loginEntryBox'
                 type='email'
                 placeholder='your_email@example.com'
                 onChange={(e) => {
                   this.handleEntry(e, 'email')
                 }}
                 value={this.state.curEmail}
                 onKeyPress={(e) => {
                   if (e.key === 'Enter') {
                     this.handleSubmit()
                   }
                 }}
          />
          <input className='loginEntryBox'
                 type='text'
                 placeholder='Your awesome username (optional)'
                 onChange={(e) => {
                   this.handleEntry(e, 'username')
                 }}
                 value={this.state.curUsername}
                 onKeyPress={(e) => {
                   if (e.key === 'Enter') {
                     this.handleSubmit()
                   }
                 }}
          />
          <input className='loginEntryBox'
                 type='password'
                 placeholder='xxxxx'
                 onChange={(e) => {
                   this.handleEntry(e, 'password')
                 }}
                 value={this.state.curPass}
                 onKeyPress={(e) => {
                   if (e.key === 'Enter') {
                     this.handleSubmit()
                   }
                 }}
          />
          <input className='loginEntryBox'
                 type='password'
                 placeholder='Confirm your password'
                 onChange={(e) => {
                   this.handleEntry(e, 'password2')
                 }}
                 value={this.state.curPass2}
                 onKeyPress={(e) => {
                   if (e.key === 'Enter') {
                     this.handleSubmit()
                   }
                 }}
          />
          <div className='linkContainer'>
            <div id='createButton' onClick={() => this.handleSubmit()}>Create!</div>
          </div>
          <div className='loader' style={{opacity: this.props.isFetching ? 1 : 0}}/>
          <div className='bottomLinkContainer'>
            <div id='loginButtons' onClick={() => this.handleClick('home')}>Home</div>
            <div id='loginButtons' onClick={() => this.handleClick('login')}>Login</div>
          </div>
        </div>
        { (this.props.err) ?
          <ToastInfo
            key={'err'}
            show={this.props.err}
            delay={6000}
            onClose={() => this.handleClose()}
            header='Failed!'
            body={
              <div style={{marginRight: '70px'}}>{this.props.err}</div>
            }
          /> : <div/>
        }
      </div>
    )
  }
}

const actions = {
  newUser, clearErr, newError
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  isFetching: state.login.fetching,
  err: state.login.err,
  accountCreated: state.sync.accountCreated
});


const CA = connect(mapStateToProps, actions)(CreateAccount);
export default withRouter(CA)