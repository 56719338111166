import {changeBox, deleteBox} from "../reducers/box";
import {setActiveBox, setToDelete} from "../reducers/meta";
import connect from "react-redux/es/connect/connect";
import React from "react";
import './Box.css'
import TextareaAutosize from 'react-textarea-autosize';
import { Rnd } from 'react-rnd'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import BoxControl from "./BoxControl";

class Box extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      readOnly: !this.props.box.selected,
      moving: false
    };
    this.handleFont = this.handleFont.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePropagation = this.handlePropagation.bind(this);
    this.controlButtons = [
      {id: 'plusButton', onClick: this.handleFont, onClickArg: 'up', handlePropagation: this.handlePropagation, icon: <AddOutlinedIcon/>, text: 'Increase selected box font'},
      {id: 'minusButton', onClick: this.handleFont, onClickArg: 'down', handlePropagation: this.handlePropagation, icon: <RemoveOutlinedIcon/>, text: 'Decrease selected box font'},
      {id: 'delButton', onClick: this.handleDelete, handlePropagation: this.handlePropagation, icon: <CloseOutlinedIcon/>, text: 'Delete selected box'}
    ]
  }

  handleDragStart(e) {
    this.setState({moving: true})
  }

  handleDragStop(e, data) {
    const { x, y } = data;
    this.setState({moving: false});
    if ((x === this.props.box.x) && (y === this.props.box.y)) {return}
    this.props.changeBox({...this.props.box, x: x, y: y});
  }

  handleSelect(e) {
    e.stopPropagation();
    document.activeElement.blur();
    this.textarea.focus();
    if (this.props.active !== this.props.box.id) {
      this.props.setActiveBox(this.props.box.id)
    }
  }

  handleText(e) {
    this.props.changeBox({...this.props.box, text: e.target.value})
  }

  handleHeightChange(h) {
    if (h > this.props.box.height) {
      this.props.changeBox({...this.props.box, height: h + this.props.box.fontSize})
    }
  }

  handleFont(e, font) {
    e.stopPropagation();
    if (font === 'up') {
      this.props.changeBox({...this.props.box, fontSize: (this.props.box.fontSize+2)})
    } else {
      this.props.changeBox({...this.props.box, fontSize: (this.props.box.fontSize-2)})
    }
  }

  handleDelete(e) {
    e.stopPropagation();
    if (this.props.toDelete !== 0) {
      this.props.deleteBox(this.props.toDelete)
    }
    this.props.setToDelete(this.props.box.id)
  }

  handleResize(ref, pos, e) {
    e.stopPropagation();
    const h = ref.style.height;
    const w = ref.style.width;
    this.props.changeBox({
      ...this.props.box,
      height: parseInt(h.substring(0, h.length-2), 10),
      width: parseInt(w.substring(0, w.length-2), 10),
      ...pos
    })
  }

  handlePropagation(e) {
    e.stopPropagation()
  }

  handleTab(e) {
    if (e.keyCode === 9) {
      e.stopPropagation();
      e.preventDefault();
      this.props.changeBox({...this.props.box, text: this.props.box.text.concat('\t')})
    }
  }

  render() {
    return (
      <div className='boxCont'>
        {(this.props.active && (this.props.active === this.props.box.id)) ?
          this.controlButtons.map((item) => <BoxControl key={item.id} {...item}/>) : <div/>}
        <Rnd
          onDragStop={(e, data) => this.handleDragStop(e, data)}
          onDragStart={(e) => this.handleDragStart(e)}
          onMouseDown={(e) => this.handlePropagation(e)}
          onTouchStart={(e) => this.handlePropagation(e)}
          onResize={(e, dir, ref, delta, pos) => this.handleResize(ref, pos, e)}
          default={{x: this.props.box.x, y: this.props.box.y, height: this.props.box.height, width: this.props.box.width}}
          minHeight={40 + this.props.box.fontSize}
          minWidth={10 + this.props.box.fontSize}>
          <div className='box'
               onClick={(e) => this.handleSelect(e)}
               style={{
                 height: this.props.box.height-10 ,
                 width: this.props.box.width-10 ,
                 margin: 5,
                 opacity: this.state.moving ? 0.75 : 1
          }}>
            <TextareaAutosize
              id='textArea'
              inputRef={tag => (this.textarea = tag)}
              wrap='soft'
              onChange={(e) => this.handleText(e)}
              onKeyDown={(e) =>  this.handleTab(e) }
              onHeightChange={(height, t) => this.handleHeightChange(height)}
              style={{
                height: this.props.box.height-10,
                width: this.props.box.width-16,
                fontSize: this.props.box.fontSize + 'px'
              }}
              spellCheck={false}
              value={this.props.box.text}
              readOnly={this.state.readOnly}>
            </TextareaAutosize>
          </div>
        </Rnd>
      </div>
    );
  }
}

const actions = {
  changeBox, setActiveBox, setToDelete, deleteBox
};

const mapStateToProps = (state) => ({
  active: state.meta.active,
  toDelete: state.meta.toDelete
});


export default connect(mapStateToProps, actions)(Box);