import React, {Component} from 'react';
import './Home.css';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {withRouter, Redirect} from "react-router-dom";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import clean from '../clean.jpg'
import mess from '../mess.jpg'

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {fixedTop: false, windowWidth: 0, hitTop: false};
    this.topRef = React.createRef();
  }

  componentDidMount() {
    this.setState({windowWidth: window.innerWidth});
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = (e) => {
    this.setState({windowWidth: window.innerWidth})
  };

  handleScroll() {
    if (this.state.windowWidth < 830) {
      if ((this.topRef.current.scrollTop > 163)) {
        this.setState({hitTop: true})
      } else if (this.state.hitTop) {
        this.setState({hitTop: false})
      }
      if ((this.topRef.current.scrollTop > 494)) {
        this.setState({fixedTop: true})
      } else if (this.state.fixedTop) {
        this.setState({fixedTop: false})
      }
    } else {
      if ((this.topRef.current.scrollTop > 150)) {
        this.setState({hitTop: true})
      } else if (this.state.hitTop) {
        this.setState({hitTop: false})
      }
      if ((this.topRef.current.scrollTop > 200)) {
        this.setState({fixedTop: true})
      } else if (this.state.fixedTop) {
        this.setState({fixedTop: false})
      }
    }
  }

  render() {
    if (this.props.token && this.props.homeRedirect) {
      return <Redirect to={{pathname: '/app'}}/>
    }
    return (
      <div className='landing'>
        <div id='homeContainer' onScroll={(e) => this.handleScroll()} ref={this.topRef}>
          <div className={this.state.fixedTop ? 'landingHeader' : ''}/>
          <div className='topSpaceFiller'>
            <div className='iconsWrap'>
              <div className='iconDesigns' id='listOutline'><ListAltOutlinedIcon/></div>
              <div className='iconDesigns' id='checkBlank'><CheckBoxOutlineBlankOutlinedIcon/></div>
              <div className='iconDesigns' id='checkYes'><CheckBoxOutlinedIcon/></div>
              <div className='iconDesigns' id='speaker'><SpeakerNotesOutlinedIcon/></div>
              <div className='iconDesigns' id='note'><NoteOutlinedIcon/></div>
            </div>
          </div>
          <div className='topLogin'>
            {((this.state.hitTop && (this.state.windowWidth < 1325)) || (this.state.windowWidth < 425)) ?
              <div/> :
              <div>Already have an
                account?
              </div>
            }
            <Link style={{textDecoration: 'none', padding: 10}} to='/login'>
              <div className='toAppButton' id='homeLogin' style={{padding: 0, margin: 0, width: 30, height: 30}}><ExitToAppOutlinedIcon/></div>
            </Link>
          </div>
          <div id={this.state.fixedTop ? 'fixedTop' : 'topSection'}
               style={((this.state.windowWidth < 830) && !this.state.fixedTop)?{flexDirection:"column", justifyContent: "center", alignItems: "center", textAlign: "center"} : {}}>
            {(this.state.windowWidth < 300) ? <div/> :
              <div id={'introText'}
                   style={(!this.state.fixedTop && (this.state.windowWidth < 425)) ? {marginTop: 0} : {}}>
                <strong>Workbor.de</strong>
              </div>
            }
            {(this.state.fixedTop && (this.state.windowWidth < 935)) ? <div/> :
              <div className='invisText'>_</div>
            }
            {
              (this.state.fixedTop && (this.state.windowWidth < 935)) ? <div/> :
                <div id={'introText'}>
                  The simple, versatile note board
                </div>
            }
            {(this.state.windowWidth < 475) ? <div/> :
              <Link style={{textDecoration: 'none'}} to='/app'>
                <div className='toAppButton' id='toApp'>Try it now!</div>
              </Link>
            }
            <Link style={{textDecoration: 'none'}} to='/new-user'>
              <div className='toAppButton' id='toNew'>New Account</div>
            </Link>
          </div>
          <div className={this.state.fixedTop ? 'topSpaceFiller' : 'spaceFiller'} id='subTextContent'>
            Organize what you want, how you want.
            All the versatility of a physical note board, wherever, whenever you need!
          </div>
          <div style={((this.state.windowWidth < 830) && this.state.fixedTop) ? {height: 130, backgroundColor: 'slategray'} : {}} />
          <div id='midSection'>
            <div className='midText'>
              How you work
            </div>
            <div className={(this.state.windowWidth < 425) ? 'smalMid' : 'midWrap' }>
              <img src={clean} alt='' className={(this.state.windowWidth < 850) ? 'smalImg' : 'imgCont' }/>
            </div>
            <div className='midText'>
              Is up to you
            </div>
            <div className={(this.state.windowWidth < 425) ? 'smalMid' : 'midWrap' }>
              <img src={mess} alt='' className={(this.state.windowWidth < 850) ? 'smalImg' : 'imgCont' } />
            </div>
          </div>
          <div id='botSection'>
            <div style={{textAlign: 'center'}}>You can also (not) find Workborde on these platforms!</div>
            <div id='socialIcons'>
              <div className='bigIco'>
                <FontAwesomeIcon icon={faTwitter} />
              </div>
              <div className='bigIco'>
                <FontAwesomeIcon icon={faFacebook} />
              </div>
              <div className='bigIco'>
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </div>
          </div>
          <div id='lastSection'>
            <div className='bottomSupport'>
              Workborde is currently in beta, and some features have yet to be implemented.
            </div>
            <div className='bottomSupport'>
              Questions, comments, or bugs: <a style={{color: 'black'}} href="mailto:support@workbor.de">support@workbor.de</a>
            </div>
            <div>
              Created by <a href='https://jaon.ca' style={{color: 'black'}}>Jason Lu</a>
            </div>
          </div>
        </div>
        <div id='homeButtonWrap'>
          <div id='homeButton'><strong>ωβ</strong><sub id='betaSub'>b</sub></div>
        </div>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {}
};

const mapStateToProps = (state) => ({
  token: state.login.token,
  homeRedirect: state.sync.homeRedirect
});

const H = connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(H)